<template>
  <!-- Register Page Area Start -->
  <section class="ekifa-login-page-area section_80">
    <b-container>
      <b-row>
        <b-col xl="4" lg="6" md="8" offset-xl="4" offset-lg="3" offset-md="2">
          <div class="login-box">
            <h3>login to your account</h3>
            <b-form>
              <b-form-group id="input-group-1" label-for="input-1">
                <b-form-input
                    id="input-1"
                    v-model="register.name"
                    placeholder="Full Name"
                    required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                  id="input-group-2"
                  label-for="input-2"
              >
                <b-form-input
                    id="input-2"
                    v-model="register.email"
                    type="email"
                    placeholder="E-mail Address"
                    required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                  id="input-group-3"
                  label-for="input-3"
              >
                <b-form-input
                    id="input-3"
                    v-model="register.password"
                    type="password"
                    placeholder="Password"
                    required
                ></b-form-input>
              </b-form-group>
                <b-form-group
                    id="input-group-4"
                    label-for="input-4"
                >
                  <b-form-input
                      id="input-4"
                      v-model="register.confirmedPassword"
                      type="password"
                      placeholder="Confirm Password"
                      required
                  ></b-form-input>
              </b-form-group>
              <b-button type="submit" >sign up</b-button>
              <div class="login-grid">
                <p>
                  Already have an account? <b-link to="/page/login">Login</b-link>
                </p>
              </div>
            </b-form>
          </div>
          <div class="social-login">
            <h3>or</h3>
            <ul>
              <li class="facebook"><b-link><i class="fa fa-facebook"></i></b-link></li>
              <li class="twitter"><b-link><i class="fa fa-twitter"></i></b-link></li>
              <li class="google-plus"><b-link><i class="fa fa-google-plus"></i></b-link></li>
              <li class="linkedin"><b-link><i class="fa fa-linkedin"></i></b-link></li>
            </ul>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
  <!-- Register Page Area End -->
</template>

<script>
export default {
  name: "RegisterArea",
  data() {
    return {
      register: {
        name: '',
        email: '',
        password: '',
        confirmedPassword: ''
      }
    }
  }
}
</script>

<style scoped>

</style>