<template>
<div>
  <!-- Breadcrumb Component -->
  <breadcrumb-area :breadcrumb="breadcrumb"/>
  <!-- Register Area Component -->
  <register-area/>
</div>
</template>

<script>
import BreadcrumbArea from "../../components/BreadcrumbArea";
import RegisterArea from "../../components/RegisterArea";
export default {
  name: "Register",
  components: {RegisterArea, BreadcrumbArea},
  data() {
    return {
      breadcrumb: {
        page: "Register",
        pageTitle: "Register"
      }
    }
  }
}
</script>

<style scoped>

</style>